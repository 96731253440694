<script lang="ts" setup>
  import type { IMovieCard } from '~/types/movies';
  import { useMovieCard } from '~/composables/useMovieCard';
  import MovieRating from '~/components/movies/MovieRating.vue';
  import VButton from '~/components/ui/VButton.vue';
  import MovieInfoGroup from '~/components/movies/MovieInfoGroup.vue';
  import MovieFavoriteButton from '~/components/movies/MovieFavoriteButton.vue';
  import MovieCardPreview from '~/components/cards/MovieCardPreview.vue';
  import { useAuthStore } from '~/stores/auth';

  const rateModal = defineAsyncComponent(() => import('~/components/modals/movieCard/rateModal/Index.vue'));
  const { onReveal } = createModal(rateModal);
  interface ICoordinates {
    left: number;
    top: number;
  }

  interface IProps {
    movie: IMovieCard;
    active?: boolean;
    last?: boolean;
    coordinates: ICoordinates;
  }

  const { user } = storeToRefs(useAuthStore());

  const props = defineProps<IProps>();
  const { name, image, imageHover, route, categories, film } = useMovieCard(props.movie);
</script>

<template>
  <div
    v-bind="$attrs"
    class="movie-card-hover"
    :style="`left: ${coordinates.left}px; top: ${coordinates.top}px;`"
    :class="{ active, last }"
  >
    <div class="movie-card-hover__body">
      <movie-card-preview :image="imageHover" :route="route" :movie="movie" />
    </div>
    <div class="movie-card-hover__footer">
      <div class="movie-card-hover__title">{{ name }}</div>
      <div class="movie-card-hover__labels">
        <movie-rating :rating="movie.rating" name="flex" size="small" />
        <movie-info-group class="movie-card-hover__group" gap="16px">
          <span v-if="categories.length > 0">{{ categories[0] }}</span>
          <span v-if="film.country">{{ film.country }}</span>
          <span v-if="film.year">{{ film.year }}</span>
        </movie-info-group>
      </div>
      <div class="movie-card-hover__controls">
        <v-button size="small" :to="route">Перейти к {{ movie?.is_film ? 'фильму' : 'сериалу' }}</v-button>
        <movie-favorite-button v-model="movie.favorite" :slug="movie?.slug" :id="movie?.id" size="small" />
        <v-button
          class="vote-rating__button"
          :class="{ 'vote-rating__button--voted': movie.my_rating.amount }"
          size="small"
          icon="vote-star"
          rounded
          :appearance="movie.my_rating.amount ? 'fill' : 'outline'"
          :color="movie.my_rating.amount ? 'white' : 'gradient'"
          media="normal"
          @click="
            onReveal({
              title: movie.name,
              rating: movie.my_rating.amount ?? 0,
              id: movie.id,
            })
          "
          v-if="user"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .movie-card-hover {
    position: absolute;
    display: grid;
    width: 560px;
    //height: 548px;
    border-radius: 32px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.19) 0%, rgba(255, 255, 255, 0.05) 100%), #000;
    //box-shadow: 0 16px 64px 0 rgba(0, 0, 0, 0.8);
    overflow: hidden;
    z-index: 10033;

    &__body {
      cursor: pointer;
      height: 348px;
    }

    &__image {
      width: 100%;
      transition: 0.4s;
      object-fit: cover;
      height: 348px;
    }

    &__footer {
      display: grid;
      gap: 16px;
      padding: 16px 24px 24px;
    }

    &__title {
      color: $main_white;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }

    &__labels {
      display: flex;
      gap: 16px;
    }

    &__group {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }

    &__info {
      display: flex;
      align-items: center;
      gap: 16px;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      color: $main_white;
    }

    &__divider {
      width: 1px;
      height: 16px;
      background-color: $main_white;
      opacity: 0.5;
    }

    &__controls {
      display: flex;
      gap: 12px;

      button {
        padding: 12px;
      }
    }

    .placeholder {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: rgba(61, 60, 60, 0.62);

      h3 {
        transform: rotate(-45deg);
      }
    }
    .vote-rating__button {
      color: color-primary;
      &--voted {
        color: $color-main;
      }
    }
    @media (max-width: $retina) {
      display: none;
    }
  }
</style>
