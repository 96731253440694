<script lang="ts" setup>
  import type { IMovieCard } from '~/types/movies';
  import { useMovieCard } from '~/composables/useMovieCard';
  import MovieLabel from '~/components/movies/MovieLabel.vue';
  import { removeFavoriteMovie, deleteTimelineById } from '~/services/modules/movies.service';
  import { useQueryClient } from '@tanstack/vue-query';
  import MovieCardHover from '~/components/cards/MovieCardHover.vue';
  import { useHover } from '~/composables/useHover';
  import { movieCacheKeys } from '~/consts/movieCacheKeys';

  interface IProps {
    movie: IMovieCard;
    active?: boolean;
    resize?: boolean;
    useRemove?: boolean;
    section?: string;
    hideStatus?: boolean;
    hideTitle?: boolean;
    removeHoverPopup?: boolean;
    hideTags?: boolean;
  }

  const props = withDefaults(defineProps<IProps>(), {
    section: 'default',
    removeHoverPopup: false,
  });
  const emit = defineEmits<{ (e: 'on-card-click', event: Event): void }>();

  const queryClient = useQueryClient();
  const { updateMoviesFavoriteParam } = useMovieCacheUpdater();

  const { route, name, image, status, accessibility, labels, showUnpublishedTag, isSpecialTariff } = useMovieCard(
    props.movie,
  );
  const { mutate: removeFromFavorite } = removeFavoriteMovie(props.movie.id);

  const { isVisible, hover, blur, coordinates } = useHover(0, props.removeHoverPopup);

  const isLoadingRemove = ref(false);

  const removeContent = async (movieId: number) => {
    if (props.section === 'favorite') {
      isLoadingRemove.value = true;
      removeFromFavorite(
        { id: movieId },
        {
          onSuccess() {
            updateMoviesFavoriteParam(movieId, false);
          },
          onSettled() {
            isLoadingRemove.value = false;
          },
        },
      );
    } else if (props.section === 'continue') {
      try {
        isLoadingRemove.value = true;
        await deleteTimelineById(props.movie.id);
        await queryClient.invalidateQueries({ queryKey: movieCacheKeys.watchingMovies });
        await queryClient.invalidateQueries({ queryKey: movieCacheKeys.watchedMovies });
        isLoadingRemove.value = false;
      } catch (e) {
        console.log(e);
      }
    }
  };
</script>

<template>
  <div
    class="movie-card"
    :class="{ resize: props.resize }"
    @mouseover="hover"
    @mouseleave="blur(false)"
    @click="emit('on-card-click')"
  >
    <v-lazy class="movie-card__image-container">
      <v-image class="movie-card__image" :src="image" :alt="name" transition="fade" transition-all>
        <template #loading>
          <v-loader-card type="movie" />
        </template>
        <template #error>
          <v-placeholder type="movie" />
        </template>
      </v-image>
    </v-lazy>
    <nuxt-link :to="route" class="movie-card__overlay" :aria-label="name">
      <button v-if="useRemove" class="movie-card__remove" @click.prevent="removeContent(props.movie.id)">
        <icon-loading v-if="isLoadingRemove" />
        <icon-close v-else />
      </button>
      <div class="movie-card__labels">
        <div v-if="showUnpublishedTag && !hideTags" class="movie-card__unpublished">Не опубликовано</div>
        <movie-label
          v-for="(label, key) in labels"
          :key="key"
          :value="label.name"
          :color="label.color"
          :text-color="label.text_color"
          v-if="!hideTags"
        />
      </div>
    </nuxt-link>
    <div class="movie-card__info">
      <div v-if="!hideTitle" class="movie-card__info-row">
        <div v-if="movie.rating" class="movie-card__rating">
          {{ movie.rating }}
        </div>
        <p class="movie-card__title">
          {{ name }}
        </p>
      </div>
      <span v-if="!isSpecialTariff && !hideStatus" class="movie-card__status" :class="[accessibility]">
        {{ status }}
      </span>
    </div>
  </div>
  <transition name="bounce">
    <teleport to="body">
      <movie-card-hover @hide="blur(true)" v-if="isVisible" :movie="movie" :coordinates="coordinates" />
    </teleport>
  </transition>
  <teleport to="body">
    <div v-if="isVisible" @mousemove="blur(true)" class="movie-card-holder" />
  </teleport>
</template>

<style lang="scss" scoped>
  @use 'assets/scss/variables/movie-card.scss' as *;

  .movie-card-holder {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10032;
  }

  .movie-card {
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 16px;
    width: 100%;
    transition: 1s;

    &:hover {
      transform: scale(1.02);
    }

    &__remove {
      position: absolute;
      top: 20px;
      right: 20px;
      width: fit-content;
      height: fit-content;
      padding: 8px;
      justify-self: flex-end;
      border-radius: 12px;
      background: map-get($movieCard, remove-btn-bg);
      transition: 0.3s;
      z-index: 2;

      svg {
        fill: map-get($movieCard, remove-btn-color);
        color: map-get($movieCard, remove-btn-color);
      }

      &:hover {
        color: map-get($movieCard, remove-btn-color-hover);
      }
    }

    &__image-container {
      width: 256px;
      height: 384px;
      border-radius: 32px;
      aspect-ratio: 2 / 3;
      overflow: hidden;
      cursor: pointer;
    }

    &__overlay {
      position: absolute;
      inset: 0;
      padding: 20px;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.4s;
    }

    &__info {
      display: grid;
      grid-template-rows: min-content;
      gap: 8px;
      width: 256px;

      &-row {
        display: flex;
        align-items: center;
        gap: 8px;
        overflow-x: hidden;
      }
    }

    &__rating {
      margin-left: 2px;
      padding: 7px 12px;
      min-width: 45px;

      color: map-get($movieCard, rating-color);
      font-size: 14px;
      font-weight: 600;
      text-align: center;

      background: map-get($movieCard, rating-bg);
      border-radius: 10px;
    }

    &__title {
      color: $text-color-main;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__status {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;

      &.buy {
        color: map-get($movieCard, status-color-buy);
      }

      &.free {
        color: map-get($movieCard, status-color-free);
      }

      &.subscribe {
        color: map-get($movieCard, status-color-subscribe);
      }
    }

    &__labels {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }

    &__unpublished {
      position: absolute;
      left: 0;
      right: 0;
      top: 30%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      min-width: 150px;
      min-height: 36px;
      padding: 4px 12px 4px 12px;
      background-color: rgba($main_red_light, 0.7);

      font-size: 14px;
      color: $main_text;
    }

    @media (max-width: $retina) {
      &__image-container {
        width: 156px;
        height: 232px;
        border-radius: 16px;
      }

      &__info {
        width: 156px;
      }

      &__title {
        font-size: 16px;
        line-height: 24px;
      }

      &__status {
        font-size: 12px;
        line-height: 16px;
      }

      &__rating {
        margin-left: 1px;
        padding: 5px 6px;
        min-width: 30px;

        font-size: 12px;
      }
    }

    &.resize & {
      &__image-container,
      &__info {
        max-width: 282px;
        width: 100%;
        min-width: 156px;
      }

      @media (max-width: $retina) {
        &__image-container {
          width: 100%;
          height: 100%;
        }
        &__info {
          width: 100%;
        }
      }
    }
  }
</style>
